import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import HomePage from '../pages/home-page';
import RecipesPage from '../pages/recipes-page';
import AboutPage from '../pages/aboutPage';
import Recipe from '../pages/recipe-page';

function App() {
    return (
        <div>
            <Helmet>
                <title>Frances Nature - healthy lifestyle blog with recipes </title>
                <meta
                    name="description"
                    content="Find healthy lifestyle inspiration with tips and healthy vegan and vegetarian recipes. Healing by reconnecting with nature."
                />
                <link rel="canonical" href="https://www.frances-nature.co.uk/" />
            </Helmet>
            <Switch>
                <Route path="/" component={HomePage} exact />
                <Route path="/home" component={HomePage} exact />
                <Route path="/recipes" component={RecipesPage} exact />
                <Route path="/about" component={AboutPage} exact />
                <Route path="/recipes/:id" component={Recipe} />
            </Switch>
        </div>
    );
}

export default App;
