import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Tag,
    Icon,
    Text,
    Flex,
    Box,
} from '@chakra-ui/react';
import { BsFilterLeft } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';

interface RecipeFilterProps {
    selectedTags: String[];
    setSelectedTags: (tags: String[]) => void;
}

const tags = ['vegan', 'vegetarian', 'romanian', 'savoury', 'sweet'];

const RecipeFilter = ({ selectedTags, setSelectedTags }: RecipeFilterProps) => {
    return (
        <Box w="100%" pl="16px">
            <Accordion allowToggle mb={5}>
                <AccordionItem border="none">
                    {({ isExpanded }) => (
                        <>
                            <h2>
                                <AccordionButton
                                    px={2}
                                    py={2}
                                    w={'fit'}
                                    _hover={{
                                        opacity: 1.0,
                                        boxShadow: 'none',
                                    }}
                                    boxShadow="none !important"
                                >
                                    {isExpanded ? (
                                        <Icon as={MdClose} w={6} h={6} color="gray.500" />
                                    ) : (
                                        <Flex color={selectedTags.length ? 'brand.900' : 'gray.500'}>
                                            <Icon as={BsFilterLeft} w={6} h={6} />

                                            <Text as="div" fontSize="sm" ml={2}>
                                                {selectedTags.length > 0 && `${selectedTags.length} filters`}
                                            </Text>
                                        </Flex>
                                    )}
                                </AccordionButton>
                            </h2>
                            <AccordionPanel px={2} pb={2} width="100%">
                                {tags.map((tag: string) => (
                                    <Tag
                                        key={tag}
                                        onClick={() => {
                                            if (selectedTags.includes(tag)) {
                                                const newSelected = [...selectedTags];
                                                newSelected.splice(selectedTags.indexOf(tag), 1);
                                                setSelectedTags(newSelected);
                                            } else {
                                                setSelectedTags([...selectedTags, tag]);
                                            }
                                        }}
                                        bg={selectedTags.includes(tag) ? 'brand.900' : 'gray.100'}
                                        color={selectedTags.includes(tag) ? 'white' : 'gray.800'}
                                        mr={2}
                                        mb={2}
                                        p={2}
                                        cursor="pointer"
                                    >
                                        {tag}
                                    </Tag>
                                ))}
                            </AccordionPanel>
                        </>
                    )}
                </AccordionItem>
            </Accordion>
        </Box>
    );
};

export default RecipeFilter;
