import { extendTheme } from '@chakra-ui/react';

const colors = {
    brand: {
        900: '#e48f42',
        800: '#ffe0b2',
        700: '#ffefc1',
    },
};

export const theme = extendTheme({ colors });
