import React from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Image } from '@chakra-ui/react';

interface RecipeCardProps {
    id: string;
    title: string;
    description: string;
    image: any;
}

const RecipeCard = ({ id, title, description, image }: RecipeCardProps) => {
    const history = useHistory();

    const handleCardClick = (id: string, title: string) => {
        history.push(`/recipes/${id}/${title.toLowerCase().split(' ').join('-')}`);
    };

    return (
        <Box key={id} as="button" onClick={() => handleCardClick(id, title)} mr={[0, 0, '24px', '24px']} mb="24px">
            <Box w="xs" h="xs" _hover={{ boxShadow: 'lg' }} borderRadius="md" boxShadow="md" overflow="hidden" ml="0px">
                <Image w="100%" h="100%" objectFit="cover" src={image?.url} alt={image.alternativeText} />
            </Box>
            <Box w="xs" textAlign="center" p={5}>
                <Box mt="1" fontWeight="semibold" as="h4" color="brand.900" lineHeight="tight">
                    {title}
                </Box>

                <Box color="gray.600" fontSize="sm">
                    {description}
                </Box>
            </Box>
        </Box>
    );
};

export default RecipeCard;
