import { ApolloClient, HttpLink } from '@apollo/client/core';

import { InMemoryCache } from '@apollo/client/cache';

const cache = new InMemoryCache();
const link = new HttpLink({
    uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
});

const client = new ApolloClient({
    cache,
    link,
});

export default client;
