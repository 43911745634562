import React from 'react';

import LandingLayout from '../../components/layouts/LandingLayout';
import { Box, Flex, Image, Heading, Stack } from '@chakra-ui/react';

const HomePage = () => {
    return (
        <LandingLayout>
            <Flex
                align="center"
                justify={{ base: 'center', md: 'space-around', xl: 'space-around' }}
                direction={{ base: 'column', md: 'row' }}
                wrap="nowrap"
                minH="70vh"
                px={8}
                mb={16}
                w="100%"
            >
                <Stack
                    spacing={4}
                    w={{ base: '80%', md: '40%' }}
                    align={['center', 'center', 'flex-start', 'flex-start']}
                >
                    <Heading
                        as="h1"
                        size="xl"
                        fontWeight="bold"
                        color="primary.800"
                        textAlign={['center', 'center', 'left', 'left']}
                    >
                        Frances Nature
                    </Heading>
                    <Heading
                        as="h2"
                        size="md"
                        color="primary.800"
                        opacity="0.8"
                        fontWeight="normal"
                        lineHeight={1.5}
                        textAlign={['center', 'center', 'left', 'left']}
                    >
                        Food | Wellbeing | Health
                    </Heading>
                </Stack>
                <Box mb={{ base: 12, md: 0 }} mt={{ base: 12, md: 0 }}>
                    <Image
                        src="https://res.cloudinary.com/frances-studio/image/upload/v1618867543/flowers_et8bsx.jpg"
                        boxSize={'sm'}
                        rounded="1rem"
                        shadow="2xl"
                        objectFit="cover"
                    />
                </Box>
            </Flex>
        </LandingLayout>
    );
};

export default HomePage;
