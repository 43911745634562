import React, { useState } from 'react';
import { Box, Stack, Text, Skeleton } from '@chakra-ui/react';

import { Helmet } from 'react-helmet';

import LandingLayout from '../../components/layouts/LandingLayout';
import RecipeCard from './RecipeCard';
import RecipeFilter from './RecipeFilter';
import { RECIPE_DATA, Recipe } from '../../data/recipes_data';

const RecipesPage = () => {
    const [selectedTags, setSelectedTags] = useState<String[]>([]);

    const recipesData = RECIPE_DATA;
    const loading = false;

    const recipeFilter = (recipe: Recipe) => {
        if (!selectedTags.length) return true;
        console.log({ selectedTags });
        return recipe.categories.some((categ: any) => selectedTags.includes(categ));
    };

    const recipes = recipesData?.filter(recipeFilter) || [];

    /* SEO */
    const schemaListItems = recipesData?.map(({ id, title }: any, idx: number) => ({
        '@type': 'ListItem',
        position: idx,
        url: `https://www.frances-nature.co.uk/recipes/${id}/${title.toLowerCase().split(' ').join('-')}`,
    }));
    const ldJson = {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: schemaListItems,
    };

    return (
        <LandingLayout>
            <Helmet>
                <title>Frances Nature Recipes </title>
                <title>Vegan and Vegetarian Recipes from various cuisines including traditional Romanian dishes</title>

                <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
            </Helmet>
            <RecipeFilter selectedTags={selectedTags} setSelectedTags={setSelectedTags} />

            <Box w="100%" px="16px">
                <Skeleton isLoaded={!loading} h="300px">
                    <Stack
                        w="100%"
                        spacing={0}
                        direction={['column', 'row']}
                        wrap="wrap"
                        shouldWrapChildren
                        justify={['center']}
                        align={['center', 'baseline']}
                    >
                        {!recipes.length ? (
                            <Text size="l" color="gray.400" mt={20} mx="auto" textAlign="center">
                                No recipes for the current filter selection
                            </Text>
                        ) : (
                            recipes.map(({ title, image, description, id }: any) => {
                                return (
                                    <RecipeCard
                                        key={id}
                                        title={title}
                                        image={image}
                                        description={description}
                                        id={id}
                                    />
                                );
                            })
                        )}
                    </Stack>
                </Skeleton>
            </Box>
        </LandingLayout>
    );
};

export default RecipesPage;
