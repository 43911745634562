import React from 'react';
import moment from 'moment';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import { Box, Image, Text, Stack, Heading, Skeleton, SkeletonText } from '@chakra-ui/react';


import LandingLayout from '../../components/layouts/LandingLayout';
import { RECIPE_DATA, Recipe } from '../../data/recipes_data';

const RecipePage = () => {
    const { id } = useParams();

    const loading = false;
    const recipe = RECIPE_DATA.find((recipe: Recipe) => recipe.id === Number(id)) || null;
    if (!recipe)
        return (
            <LandingLayout>
                <Heading as="h1" size="l" color="grey" mt={20} textAlign="center">
                    Oops...Something went wrong :(
                </Heading>
            </LandingLayout>
        );
    const { title, ingredients, instructions, image, description, duration, serves, difficulty } = recipe;

    const imageUrl = image?.url;

    const momentDuration = moment.duration(duration, 'minutes');

    const durationMinutes = momentDuration.minutes();
    const durationHours = momentDuration.hours();

    const formattedDuration = `${durationHours ? `${durationHours}h ` : ''}${
        durationMinutes ? `${durationMinutes}min` : ''
    }`;

    const ldJson = {
        '@context': 'https://schema.org',
        '@type': 'Recipe',
        name: title,
        image: [imageUrl],
        author: {
            '@type': 'Person',
            name: 'Frances Nature',
        },
        description,
        // prepTime: 'PT20M',
        // cookTime: 'PT30M',
        totalTime: `PT${duration}M`,
        keywords: 'vegetarian,healthy,vegan',
        recipeCategory: 'vegan',
        recipeCuisine: 'healthy',
    };
    return (
        <LandingLayout>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
            </Helmet>
            <Stack align="center" minH="70vh" px={8} mb={16} w={{ sm: '100%', md: '100%' }}>
                <SkeletonText isLoaded={!loading} mb={2} textAlign="center">
                    <Heading mb={2} as="h1" size="xl" fontWeight="bold" color="brand.900" textAlign="center">
                        {title}
                    </Heading>
                    <Text fontSize="xs" textAlign="center" color="primary.800" opacity="0.6">
                        {description}
                    </Text>
                    <Text mb={2} fontSize="xs" textAlign="center" color="primary.800" opacity="0.6">
                        {`Duration: ${formattedDuration} | ${serves} | Difficulty: ${difficulty}`}
                    </Text>
                </SkeletonText>
                <Box w={{ sm: '80%', md: '80%' }} mb={10} align="center">
                    <Skeleton isLoaded={!loading}>
                        <Image
                            src={imageUrl}
                            alt={image?.alternativeText}
                            borderRadius="md"
                            shadow="sm"
                            objectFit="cover"
                            boxSize={'lg'}
                        />
                    </Skeleton>
                </Box>

                <Stack direction={['column', 'column', 'row', 'row']} minH="70vh" mb={16} mt={10}>
                    <Box
                        px={[5, 10]}
                        borderRightWidth={[0, 0, '1px', '1px']}
                        borderBottomWidth={['1px', '1px', 0, 0]}
                        borderStyle="dashed"
                        flex="1"
                        align={['center', 'left']}
                    >
                        <SkeletonText isLoaded={!loading}>
                            <Heading as="h2" size="l" mb={5} fontWeight="bold" color="brand.900">
                                Ingredients
                            </Heading>
                            <Text as="div" fontSize="s" mb={7} textAlign="left" color="primary.800" opacity="0.6">
                                <ReactMarkdown children={ingredients} />
                            </Text>
                        </SkeletonText>
                    </Box>
                    <Box px={[2, 5]} pt={[5, 5, 0, 0]} flex="3">
                        <SkeletonText isLoaded={!loading} width={'100%'}>
                            <Heading as="h2" size="l" mb={5} fontWeight="bold" color="brand.900">
                                Instructions
                            </Heading>
                            <Text as="div" fontSize="s" mb={10} textAlign="left" color="primary.800" opacity="0.6">
                                <ReactMarkdown children={instructions} />
                            </Text>
                        </SkeletonText>
                    </Box>
                </Stack>
            </Stack>
        </LandingLayout>
    );
};

export default RecipePage;
