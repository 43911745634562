import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex, Text, Image } from '@chakra-ui/react';
import logo from '../../assets/brandLogo.png';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { useLocation } from 'react-router';

const menuItems = [
    { title: 'Home', url: '/home' },
    { title: 'Recipes', url: '/recipes' },
    { title: 'About', url: '/about' },
];

interface MenuItemProps {
    isLast: boolean;
    to: string;
    selected: boolean;
    title: string;
}

const MenuItem = ({ title, isLast, to = '/', selected }: MenuItemProps) => (
    <Text
        fontWeight={selected ? 'bold' : 'normal'}
        mb={{ base: isLast ? 0 : 8, sm: 0 }}
        mr={{ base: 0, sm: isLast ? 0 : 8 }}
        display="block"
    >
        <Link to={to}>{title}</Link>
    </Text>
);

const Header = () => {
    const [show, setShow] = React.useState(false);
    const location = useLocation();

    const toggleMenu = () => setShow(!show);

    return (
        <Flex
            as="nav"
            align="center"
            textAlign="center"
            justify="space-between"
            wrap="wrap"
            w="100%"
            mb={2}
            p={8}
            bg={'transparent'}
            color={'brand.900'}
        >
            <Box align="center">
                <Link to={'/home'}>
                    <Image maxH={'100px'} src={logo} alt="logo" objectFit="cover" />
                </Link>
            </Box>

            <Box display={{ base: 'block', md: 'none' }} onClick={toggleMenu} cursor="pointer">
                {show ? (
                    <CloseIcon boxSize={4} color={['grey', 'grey', 'brand.900', 'brand.900']} />
                ) : (
                    <HamburgerIcon boxSize={6} color={['grey', 'grey', 'brand.900', 'brand.900']} />
                )}
            </Box>

            <Box
                mt={10}
                borderBottomWidth={['1px', '1px', 0, 0]}
                pb={5}
                display={{ base: show ? 'block' : 'none', md: 'block' }}
                flexBasis={{ base: '100%', md: 'auto' }}
            >
                <Flex
                    align={['center', 'center', 'center', 'center']}
                    justify={['center', 'space-between', 'flex-end', 'flex-end']}
                    direction={['column', 'row', 'row', 'row']}
                    pt={[2, 2, 0, 0]}
                >
                    {menuItems.map((item, idx) => (
                        <MenuItem
                            title={item.title}
                            key={item.url}
                            selected={location.pathname.includes(item.url)}
                            to={item.url}
                            isLast={idx === menuItems.length - 1}
                        />
                    ))}
                </Flex>
            </Box>
        </Flex>
    );
};

export default Header;
