import React from 'react';
import moment from 'moment';

import LandingLayout from '../../components/layouts/LandingLayout';
import { Box, Stack, Image, Text } from '@chakra-ui/react';

import aboutPhoto from '../../assets/aboutPhoto.png';

const AboutPage = () => {
    return (
        <LandingLayout>
            <Stack w="100%" direction={['column', 'column', 'row', 'row']} spacing={4} align="center" mb="20px">
                <Box mb={{ base: 12, md: 0 }} mt={{ base: 12, md: 0 }} px="30px">
                    <Image src={aboutPhoto} boxSize={'sm'} rounded="1rem" shadow="2xl" objectFit="cover" />
                </Box>
                <Box w="70%" px={[0, '30px']} textAlign="left" fontSize="sm" color="primary.800">
                    <Text fontSize="lg" fontWeight="500" pb="12px" color="brand.900">
                        Welcome to Frances Nature
                    </Text>
                    <Text pb="4px">
                        If you love a healthy and active lifestyle, nature and its beauty, or you’re searching for vegan
                        or vegetarian inspiration, you’re in the right place. I will be sharing with you my favourite
                        recipes, my learnings from trying to live a healthier lifestyle, and my journey of reconnecting
                        with food and nature.
                    </Text>
                    <Text pb="12px">
                        This started as a pandemic project to keep me accountable on my health journey and to keep me
                        busy during all those days spent indoors. But it has grown into an activity that helps me learn,
                        practice my coding skills, and allows me to collect my recipes and thoughts in one place.
                    </Text>
                    <Text fontSize="lg" fontWeight="500" pb="12px" color="brand.900">
                        About me
                    </Text>
                    <Text pb="4px">
                        For those of you who don't know me, my name is Francesca and I’m{' '}
                        {moment().diff(moment('1993-06-30'), 'years')} years old. I live in London, but I was born and
                        raised in Romania. I came to the UK for university and I have been living here since, working as
                        a Software Engineer.
                    </Text>
                    <Text pb="4px">
                        I have always been an active person, interested in healthy eating, but I finally got serious
                        about it in 2020 when my health was low and I needed help. I embarked on a journey of healthy
                        eating adjusted to my needs, with the help of a naturopath. Only then, I realised how
                        disconnected I was from nature, from the ways I used to live and eat when I was a child back in
                        Romania. This helped me get better and find a passion for cooking. It helped me reconnect with
                        food and nature. It helped me pay attention to my body and connect with it. It reminded me of
                        all the good food we have in Romania and it inspired me to learn how to make it.
                    </Text>
                    <Text pb="4px">
                        Today, I am sharing my experience with you. Give the recipes a try, and I’m sure they will
                        become one of your dinner regulars.
                    </Text>
                </Box>
            </Stack>
        </LandingLayout>
    );
};

export default AboutPage;
