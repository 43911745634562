import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import PageViewTracking from './PageViewsTracking';

import App from './app/App';
import client from './utils/apolloClient';

import { theme } from './theme';
import * as serviceWorker from './serviceWorker';
import './index.css';

import 'fontsource-roboto';

const TRACKING_ID = 'UA-194775868-1';
ReactGA.initialize(TRACKING_ID);
const history = createBrowserHistory();

ReactDOM.render(
    <Router>
        <PageViewTracking history={history} />
        <ApolloProvider client={client}>
            <ChakraProvider theme={theme}>
                <App />
            </ChakraProvider>
        </ApolloProvider>
    </Router>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
