import React from 'react';
import { Flex } from '@chakra-ui/react';
import Header from '../sections/Header';

interface LandingLayoutProps {
    children?: React.ReactNode;
    rest?: any;
}
const LandingLayout = ({ children, rest }: LandingLayoutProps) => (
    <Flex direction="column" align="center" maxW={{ base: 'auto', xl: '1200px' }} m="0 auto" {...rest}>
        <Header />
        {children}
    </Flex>
);

export default LandingLayout;
